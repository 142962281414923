
















































































import { Component, Vue } from 'vue-property-decorator';

import FlagshipProfilingStepGetReadyViewModel
  from '@/vue-app/view-models/components/flagship/flagship-profiling/flagship-profiling-step-get-ready-view-model';

@Component({ name: 'FlagshipProfilingStepGetReady' })
export default class FlagshipProfilingStepGetReady extends Vue {
  flagship_get_ready_view_model = Vue.observable(
    new FlagshipProfilingStepGetReadyViewModel(this),
  );
}
